table.table {
  td.name {
    font-weight: bold;
    text-align: right;
  }
}

.loaderHeader {
  margin-top: 80px !important;
}

.flex-header {
  display: flex;
  justify-content: space-between;

  .title {
    padding: 3px 0;
  }

  .btn {
    .button {
      margin-right: 0;
    }
  }
}

th .myheader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .ui.header {
    margin: 0;
    padding: 6px 9px;
  }
}

.jsLogs {
  .ui.feed .event {
    padding: 0.3em 1em 0.7em;

    .flexer {
      display: flex;
      flex-direction: row;

      .time {
        font-size: 0.6em !important;
        opacity: 0.6;
      }

      .text {
        margin-left: 5px;

        .screenshot {
          cursor: pointer;
        }
      }
    }
  }
}

.modal.log {
  &.screenshot {
    img {
      max-width: none;
    }
  }

  &.html {
    iframe {
      background-color: white;
      border: 0;
    }
  }
}

.logs {
  .ui.feed .event .content {
    padding: 0.3em 1em;
  }
}

.cardNumber {
  b {
    margin: 0 5px;
  }
}

.valueText {
  border: 0;
  padding: 0;
  margin: 0;
  width: 100%;
}

button.message-right {
  float: right;
  padding: 5px 8px 6px !important;
  margin-top: -3px !important;

}

.iframe-nopad {
  .scrolling.content {
    padding: 0 !important;
  }
}

.bg-light-red {
  background-color: #fcf0f1 !important;
}

.bg-light-orange {
  background-color: #faf1ed !important;
}

.bg-light-yellow {
  background-color: #fcf8ed !important;
}

.bg-light-blue {
  background-color: #eef6f9 !important;
}

.bg-light-green {
  background-color: #f9fbed !important;
}

.emails-row {
  font-size: 11px;
}

.emails-email {
  font-size: 10px;
  max-width: 100px;
  white-space: nowrap; /* Отменяем перенос текста */
  overflow: hidden; /* Обрезаем содержимое */
}

.tiny-ul {
  margin: 0;
  padding: 0 0 0 20px;

  li {
    text-overflow: ellipsis;
    max-width: 100px;
    overflow: hidden;
  }
}

.error-block {
  margin: 10px 0;
  padding: 25px;
  font-weight: bold;
  border: 2px solid #950000;
  color: #950000;
  background-color: #fcf0f1 !important;
}