.redRow {
  background-color: #d7d7d7;
  opacity: 0.65;
}

.rt-td {
  .label {
    padding: 3px 7px 4px;
    font-size: 11px;
    font-weight: 100;
  }
}

.progressContainer {
  width: 100%;
  height: 20px;
  background-color: #dadada;
  border-radius: 2px;

  .progressBar {
    height: 100%;
    background-color: #b9c6c9;
    border-radius: 2px;
    transition: all .2s ease-out;
  }
}

.btns {
  font-size: 12px;
}

.success {
  display: flex;
  justify-content: center;

  .circle {
    width: 20px;
    height: 20px;
    border-radius: 100%;
  }

  span {
    margin-left: 3px;
    font-weight: bold;
  }
}

.modal {
  padding: 0;

  textarea {
    border: 0;
    width: 100%;
    min-height: 250px;
    padding: 5px;
  }
}

code {
  font-size: 10px;
  line-height: 11px;
}