body {
  background: #fcfcfc !important;
}

.ReactTable {
  background: #FFFFFF;
}

.breadcrumbs {
  margin-bottom: 15px;
}

.top-menu {
  .header.item {
    padding-left: 0;
    span {
      margin-left: 10px;
      font-weight: 100;
    }
  }

  .userEmail {
    font-weight: 100;
    font-size: 11px;
  }
}

.main.container {
  margin-top: 70px;
}