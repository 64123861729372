table.table {
  td.name {
    font-weight: bold;
    text-align: right;
  }
}

.loaderHeader {
  margin-top: 80px !important;
}

th .myheader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .ui.header {
    margin: 0;
    padding: 6px 9px;
  }
}

.jsLogs {
  .ui.feed .event {
    padding: 0.3em 1em 0.7em;

    .flexer {
      display: flex;
      flex-direction: row;

      .time {
        font-size: 0.6em !important;
        opacity: 0.6;
      }

      .text {
        margin-left: 5px;

        .screenshot {
          cursor: pointer;
        }
      }
    }
  }
}

.modal.log {
  &.screenshot {
    img {
      max-width: none;
    }
  }

  &.html {
    iframe {
      background-color: white;
      border: 0;
    }
  }
}

.logs {
  .ui.feed .event .content {
    padding: 0.3em 1em;
  }
}

.cardNumber {
  b {
    margin: 0 5px;
  }
}

.valueText {
  border: 0;
  padding: 0;
  margin: 0;
  width: 100%;
}

.CodeMirror {
  padding: .78571429em 1em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, .15);
  outline: 0;
  color: rgba(0, 0, 0, .87);
  border-radius: .28571429rem;
  margin-top: 20px;
}

.bg-light-red {
  background-color: #fcf0f1 !important;
}

.bg-light-orange {
  background-color: #faf1ed !important;
}

.bg-light-yellow {
  background-color: #fcf8ed !important;
}

.bg-light-blue {
  background-color: #eef6f9 !important;
}

.bg-light-green {
  background-color: #f9fbed !important;
}