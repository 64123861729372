td.check {
  width: 40px;
}

.CodeMirror {
  padding: .78571429em 1em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, .15);
  outline: 0;
  color: rgba(0, 0, 0, .87);
  border-radius: .28571429rem;
  margin-top: 20px;
}