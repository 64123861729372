.request-refund {
  background-color: #dff6fe;
}

.refund-finished {
  opacity: 0.7;
  background-color: #f0f0f0;
}

.refund-to-pay {
  font-weight: bold;
  background-color: #ebfef1;
}